.title {
  font-size: 65px;
  font-weight: 300;
  line-height: 95%;
  letter-spacing: -0.65px;
  color: #192f44;
}

.imageContainer {
  width: 250px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.beyondMeatLogo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.monsterLogo {
  width: 180px;
}

.mvcLogo {
  width: 250px;
  mix-blend-mode: multiply;
}

.sfbfLogo {
  mix-blend-mode: multiply;
}

@media (max-width: 1439px) {
  .container {
    padding: 68px 40px;
  }

  .logosContainer {
    max-width: 880px;
  }
}

@media (max-width: 959px) {
  .container {
    padding: 48px 40px 68px;
  }

  .title {
    font-size: 50px;
    letter-spacing: -0.5px;
  }

  .logosContainer {
    max-width: 520px;
  }

  .imageContainer {
    width: 188px;
    height: 68px;
  }
}

@media (max-width: 567px) {
  .title {
    font-size: 32px;
    letter-spacing: -0.32px;
  }
}
